<template>
	<el-dialog
	    width="1300px"
		top="6vh"
		:destroy-on-close="true"
		@close="close"
		@open="open"
	  >
		<div class="a">
			<div class="a-title">添加商品</div>
			<div class="p">
				<div class="p-top">
					<el-input
						v-model="value"
						:rows="1"
						type="textarea"
						placeholder="请输入您想查询的信息"
						@keydown.enter="search"
						style="width: 300px;"
					/>
					<div style="display: flex;">
						<el-button style="margin-left: 10px;" size="mini" @click="search" type="primary">查询</el-button>
						<div style="color: #999999;margin-left: 20px;font-size: 12px;">批量添加订货号（型号）和数量，用空格隔开，也可从Excel中复制粘贴，然后按查询。</div>
					</div>
				</div>
				<!-- 商品信息 -->
				<lida-table
					:pagination="true" 
					:cutHeight="150"
					:data="data"
					border
					:page="page"
					:pageSize="pageSize"
					:total="total"
					@change="pageChange"
					@selection-change="selectGoods"
					ref="table">
						<el-table-column :selectable="checkSelectable" type="selection" width="55" fixed/>
						<lida-table-column label="产品信息" fixed>
							<template #default="scope">
								<div class="g-info">
									<div class="g-icon">
										<img :src="scope.row.src"/>
									</div>
									<div class="g-right">
										<div class="g-r-title">
											{{scope.row.productName}}
											<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
										</div>
										<div class="g-r-data">
											{{scope.row.barCode || '无'}}
											<span>|</span>
											{{scope.row.seriesName || '无'}}
											<span>|</span>
											{{scope.row.skuNumber || '无'}}
										</div>
									</div>
								</div>
							</template>
						</lida-table-column>
						<lida-table-column label="订货号" prop="articleNo" />
						<lida-table-column label="库存" prop="quantity" />
						<lida-table-column label="数量" prop="qty">
							<template #default="scope">
								<el-input-number v-model="scope.row.qty" :min="0" />
							</template>
						</lida-table-column>
						<lida-table-column label="操作" fixed="right">
							<template #default="scope">
								<el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
							</template>
						</lida-table-column>
				</lida-table>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions , mapState } from "vuex";
	import { ElMessage } from 'element-plus'
	import offerProfitCompute from '@/common/js/offerProfitCompute.js'
	import moment from "moment"
	export default{
		data(){
			return{
				value:'',  //搜索值
				data:[],  //列表数据
				total:0,
				page:1,
				pageSize:10,
			}
		},
		props:{
			ids:{ //需要置灰的商品ids
				type:Array,
				default:() => {
					return [];
				}
			}
		},
		methods:{
			...mapActions('stockModule',[
				'stockPage'
			]),
			search(){ //搜索的点击事件
				this.getList();
				//阻止浏览器默认事件
				let e = window.event || arguments[0];
				if (e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) {
					e.returnValue = false;
					return false;
				}
			},
			getList(){
				this.stockPage({
					wd:this.value,
					pageNo:this.page,
					pageSize:this.pageSize,
				}).then(res => {
					let {code,data} = res.data;
					if(code === "0"){ //请求成功
						//每次搜索结果数据累加
						data.rows.map(item => {
							item.qty = 1; //数量
							item.disabled = this.ids.some(v => v === item.skuId);  //是否禁用
						})
						this.data = data.rows;
						this.total = data.totalCount;
					}
				})
			},
			deleteRow(obj){ //删除商品的事件
				this.data.splice(obj.$index,1);
			},
			close(){ //关闭弹框
				this.data = [];
				this.value = '';
				this.$emit('update:modelValue', false);
			},
			confirm(){ //确认的点击事件
				var data = JSON.parse(JSON.stringify(this.data.filter(v => !v.disabled)));
				data = data.filter(v => v.active);
				if(data.length){
					data.map(item => {
						delete item.disabled;
					})
					this.$emit('success',data);
					this.close();
				}else{
					ElMessage({
						type:'warning',
						message:'请选择要添加的商品',
					})
				}
			},
			inputLv(row,type,linkage){ //监听面价点数和利润点数输入的事件
				row = offerProfitCompute(row,type,linkage);
			},
			clearData(){
				this.value = '';
				this.data = [];
			},
			selectGoods(data){
				this.data.map(item => {
					item.active = data.some(v => v.id === item.id);
				})
			},
			checkSelectable(row){
				return !row.disabled;
			},
			pageChange(page,pageSize){
				this.page = page;
				this.pageSize = pageSize;
				this.getList();
			},
			open(){
				this.getList();
			}
		},
	}
</script>

<style scoped lang="less">
	.a{
		padding: 20px;
		.a-title{
			padding-bottom: 20px;
			font-weight: bold;
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
	}
	.p{
		.p-top{
			display: flex;
			align-items: center;
			padding-bottom: 20px;
		}
	}
	.t-row{
		display: flex;
		align-items: center;
		span{
			margin-left: 5px;
		}
	}
	.t-profit{
		display: flex;
		flex-direction: column;
		font-size: 14px;
		.t-profit-i{
			display: flex;
			span{
				width: 100px;
				color: #999999;
			}
		}
	}
	
</style>
