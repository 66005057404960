<template>
	<div>
		<search-table @commands="handleScreen">
			<template v-slot>
				<el-button
					type="primary"
					@click="outStock"
					size="mini">出库</el-button>
			</template>
		</search-table>
		<div class="info">
			<Sum-number context="总入库单数" :number="total"></Sum-number>
			<div style="margin-top: 10px;">
				<lida-table
					pagination
					:cutHeight="0"
					:data="data"
					:pageSize="pageSize"
					:page="page"
					border
					@change="pageChange"
					:total="total"
					ref="table">
						<lida-table-column 
							v-for="item in deploy"
							:label="item.title" 
							:prop="item.key" 
							:key="item.key"
							fixed/>
						<lida-table-column label="操作" fixed="right">
							<template #default="scope">
								<!-- <el-button size="mini" @click="seeDetails(scope.row)" type="text">查看</el-button> -->
								<div @click="seeDetails(scope.row)" style="color: #D84146;cursor: pointer;">查看</div>
							</template>
						</lida-table-column>
				</lida-table>
			</div>
		</div>
	</div>
	<add-out-stock-dialog
		v-model="showOutStock"
		@success="addSuccess"
	/>
</template>

<script>
	import { mapActions, mapState } from 'vuex'
	import SumNumber from '@/components/publicComponent/sumNumber.vue'
	import searchTable from '@/components/publicComponent/searchTable/index.vue'
	import addOutStockDialog from '../components/dialog/addOutStock.vue'
	export default{
		data(){
			return{
				total:0,
				page:1,
				pageSize:10,
				data:[],
				deploy:[
					{title:'出库时间',key:'time',fixed:true},
					{title:'出库类型',key:'type'},
					{title:'出库单号',key:'number'},
					{title:'采购用户',key:'userText'},
					{title:'关联销售单',key:'purchaseNumber'},
					{title:'出库总数量',key:'outQty'},
					{title:'备注',key:'remarks'},
				],
				showOutStock:false,  //出库弹框的隐藏显示
			}
		},
		components:{
			SumNumber,
			searchTable,
			addOutStockDialog,
		},
		computed:{
			...mapState('stockModule',[
				'outStockType',  //出库类型
			])
		},
		methods:{
			...mapActions('stockModule',[
				'stockPageOut',  //出库明细列表
			]),
			getList(){ //获取列表数据
				this.stockPageOut({
					pageNo:this.page,
					pageSize:this.pageSize
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						data.rows.map(item => {
							item.userText = item.userName || item.userPhone ?
							  (item.userName || '')  + ' - ' + (item.userPhone || '') : '无'; 
							item.type = this.getText('outStockType',item.type);
						})
						this.data = data.rows;
						this.total = data.totalCount;
					}
				})
			},
			seeDetails(row){ //查看入库明细详情
				this.$router.push({
					path:'/stock/outStockDetails',
					query:{
						id:row.id
					}
				})
			},
			outStock(){ //添加出库列表
				this.showOutStock = true;
			},
			addSuccess(){ //出库成功刷新列表页
				this.getList();
			},
			getText(type,val){
				return this[type].some(v => v.type === val || v.type === Number(val)) ?
				this[type].filter(v => v.type === val || v.type === Number(val))[0].title : '';
			},
		},
		created(){
			this.getList();
		}
	}
</script>

<style scoped lang="less">
	
</style>
